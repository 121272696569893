import React, {useState, useEffect} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import get from 'lodash/get';

import Master from '../templates/Master.jsx';
import SEO from '../components/shared/seo';
import SearchButton from '../components/shared/SearchButton';
// import RichText from '../components/shared/RichText';

const Container = styled.section`
	width: 100%;
	max-width: 70rem;
	margin: 0 auto;
	padding: 2rem 0 3rem 0;
	display: flex;
	justify-content: space-between;
	@media (max-width: 60rem) {
		flex-wrap: wrap;
		justify-content: center;
	}
	> div {
		width: 60%;
		padding: 0 1rem;
		&:last-of-type {
			width: 40%;
			@media (max-width: 60rem) {
				width: 100%;
			}
		}
		@media (max-width: 60rem) {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
	form {
		width: 100%;
	}
`;
const Heading = styled.div`
	width: 100%;
	max-width: 70rem;
	margin: 0 auto;
	padding: 3rem 2rem 0 2rem;
	h1 {
		color: ${({theme}) => theme.colors.primary};
	}
`;
const Formfield = styled.div`
	width: 90%;
	max-width: 500px;
	padding: 1rem;
	@media (max-width: 1200px) {
		padding: 1rem 0;
	}
	input,
	textarea {
		width: 100%;
		display: inline-block;
		padding: 0.75rem;
	}
`;
const Button = styled.button`
	background-color: ${({theme}) => theme.colors.primary};
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	padding: 0.5rem 1rem;
	width: max-content;
	border: 2px solid ${({theme}) => theme.colors.primary};
	appearance: none;
	transition: 0.25s all ease-in-out;
	cursor: pointer;
	:hover {
		background-color: #ffffff;
		color: ${({theme}) => theme.colors.primary};
	}
	@media (max-width: 640px) {
		font-size: 0.875rem;
	}
`;

const Index = ({data: {kontentItemPage: data}}) => {
	const [width, setWidth] = useState('');

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	const props = {
		title: get(data, 'elements.title.value'),
		hero: get(data, 'elements.hero.linked_items'),
		content: get(data, 'elements.content.resolvedData.html'),
		linkedItems: get(data, 'elements.content.linked_items'),
		links: get(data, 'elements.content.links'),
		images: get(data, 'elements.content.images'),
	};
	return (
		<Master>
			<SEO title={props.title} />
			{(() => {
				if (width <= 1024) {
					return (
						<section>
							<SearchButton />
						</section>
					);
				}
			})()}
			<Heading>
				<h1>Contact TAP School Photos</h1>
			</Heading>
			<Container>
				<div>
					<form name="contact" method="POST" data-netlify="true">
						<input type="hidden" name="form-name" value="contact" />
						<Formfield>
							<input
								type="text"
								name="name"
								placeholder="Full Name"
							/>
						</Formfield>
						<Formfield>
							<input
								type="email"
								name="email"
								placeholder="Email Address"
							/>
						</Formfield>
						<Formfield>
							<input
								type="phone"
								name="phone"
								placeholder="Phone"
							/>
						</Formfield>
						<Formfield>
							<textarea
								name="message"
								placeholder="Message"></textarea>
						</Formfield>
						<Formfield>
							<Button type="submit">Submit</Button>
						</Formfield>
					</form>
				</div>
				<div>
					<div dangerouslySetInnerHTML={{__html: props.content}} />
				</div>
			</Container>
		</Master>
	);
};

export default Index;

export const query = graphql`
	query($slug: String!) {
		kontentItemPage(elements: {slug: {value: {eq: $slug}}}) {
			elements {
				title {
					value
				}
				slug {
					value
				}
				content {
					links {
						codename
						link_id
						type
						url_slug
					}
					images {
						image_id
						url
						description
					}
					value
				}
				hero {
					value {
						...kontentItemSectionHeroFragment
					}
				}
			}
		}
	}
`;
